import { userServiceApiSlice } from "state/services/userServiceApiSlice";

// The `userServiceApiMutations` object provides hooks that allow you to make mutations to the user service API.
export const userServiceApiMutations = userServiceApiSlice.injectEndpoints({
  // The `endpoints` object is used to define the API endpoints for the user service.
  endpoints: (builder) => ({
    // The `updateUser` mutation is used to update a user entry.
    updateUser: builder.mutation({
      query: (body) => ({
        url: "/users",
        method: "PUT",
        body: body,
      }),
      transformResponse: (responseData) => {
        return responseData.updated[0];
      },
      invalidatesTags: ["User"],
    }),
    // The `createUser` mutation is used to create a new user entry.
    createUser: builder.mutation({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body: body,
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      invalidatesTags: ["User"],
    }),
    // The `updateUserOrgStatus` mutation is used to update a user's organization membership status.
    updateUserOrgStatus: builder.mutation({
      query: ({ userId, body, attribute }) => ({
        url: `/users/${userId}/${attribute}`,
        method: "PATCH",
        body: body,
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      invalidatesTags: ["User"],
    }),
    updateUserOrgStatusBatchWithExcel: builder.mutation({
      query: ({ body }) => ({
        url: `/users/bulkupload`,
        method: "POST",
        body: body,
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      invalidatesTags: ["User"],
    }),
    updateUserOrgStatusBatch: builder.mutation({
      async queryFn(
        { rowData, platformId, orgId, rejectReason, otherReason, userEmail },
        _queryApi,
        _extraOptions,
        baseQuery
      ) {
        for (const row of rowData) {
          const body = {
            platforms: {
              [platformId]: {
                organizations: {
                  [orgId]: {
                    membership_status:
                      row.status === "joined"
                        ? "Remove-Requested"
                        : "Not-Requested",
                    reason:
                      rejectReason === "Other" ? otherReason : rejectReason,
                    changed_by: userEmail.toLowerCase(),
                  },
                },
              },
            },
          };

          const res = await baseQuery({
            url: `/users/${row?.id}/membership_status`,
            method: "PATCH",
            body: body,
          });

          if (res.error) {
            return { error: res.error };
          }
        }
        return { data: rowData.length };
      },
      invalidatesTags: ["User"],
    }),

    syncUsers: builder.mutation({
      query: ({orgId}) => ({
        url: `/users/populate?org_id=${orgId}`,
        method: "PUT",
      }),
      transformResponse: (responseData) => {
        return responseData;
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useCreateUserMutation,
  useUpdateUserOrgStatusMutation,
  useUpdateUserOrgStatusBatchMutation,
  useUpdateUserOrgStatusBatchWithExcelMutation,
  useSyncUsersMutation
} = userServiceApiMutations;
