import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Button,
  createTheme,
  InputAdornment,
  styled,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { useGetPlatformsQuery } from "state/rtkQuery/platform/platformQueries";
import {
  useSyncUsersMutation,
  useUpdateUserOrgStatusBatchMutation,
} from "state/rtkQuery/user/userMutations";
import { useGetPopulateStatusQuery } from "state/rtkQuery/user/userQueries";
import { userServiceApiSlice } from "state/services/userServiceApiSlice";

import LoadingButton from "components/LoadingButton/LoadingButton";

import LargeDataPagination from "./LargeDataPagination/LargeDataPagination";
import SyncUsersConfirmationModal from "./SyncUsersConfirmationModal/SyncUsersConfirmationModal";
import ReusableMultiSelectModal from "./ReusableMultiSelectModal";

const ReusableDataGrid = ({
  columns,
  rows,
  orgName,
  viewType,
  setCurrentPage,
  currentPage,
  totalRecords,
  isLoading,
  setQuery,
  query,
  checkboxSelection,
  tabDescription,
}) => {
  const [queryData, setQueryData] = useState([]);
  const [isDataProcessingloading, setIsDataProcessingLoading] = useState(true);
  const [isSyncInitiatedByUser, setIsSyncInitiatedByUser] = useState(false);
  const [showMultiSelectModal, setShowMultiSelectModal] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [isSyncInProgress, setIsSyncInProgress] = useState(false);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const userEmail = useSelector((state) => state.auth.userAccount?.username);
  const { data: platformData } = useGetPlatformsQuery();
  const [permissions, setPermissions] = useState([]);
  const userPermissions = useSelector((state) => state.auth.permissions);
  const isUserAuthorized =
    permissions?.userType === "Owner" || permissions?.userType === "Approver";

  // RTK Query for fetching population status
  const {
    data: populateStatusData,
    isLoading: isPopulateLoading,
    isError: isPopulatingError,
    refetch: refetchPopulateStatus, // Destructure refetch function
  } = useGetPopulateStatusQuery(
    { orgId },
    {
      skip: !orgId,
    }
  );

  // Theme for tooltip
  const welcomeToolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.9rem", // Adjust this value to your needs
            padding: "8px 12px",
          },
        },
      },
    },
  });

  //Functions to Handle Opening and Closing of Sync Modal
  const openSyncModal = () => {
    setIsSyncModalOpen(true);
  };

  const closeSyncModal = () => {
    setIsSyncModalOpen(false);
  };

  const populateStatus = populateStatusData || {};

  // Sync Users Mutation
  const [
    syncUsers,
    {
      isLoading: isSyncUsersLoading,
      isSuccess: isSyncUsersSuccess,
      isError: isSyncUsersError,
    },
  ] = useSyncUsersMutation();

  // Handle the Sync users button click
  const handleSyncClick = () => {
    setIsSyncInitiatedByUser(true); // Track that the sync was initiated by the user
    setIsSyncInProgress(true); // Set sync in progress
    closeSyncModal();
    syncUsers({ orgId })
      .unwrap()
      .then((response) => {
        setTimeout(() => {
          refetchPopulateStatus();
        }, 2000); // Delay the call by 2 seconds

        // Start interval to check populate status every 30 seconds
        const intervalId = setInterval(() => {
          refetchPopulateStatus();
        }, 30000);

        // Clear interval when status becomes "ended"
        const statusCheckInterval = setInterval(() => {
          refetchPopulateStatus(); // Ensure status is checked frequently
          if (populateStatusData?.status === "ended") {
            clearInterval(intervalId);
            clearInterval(statusCheckInterval);
            if (isSyncInitiatedByUser) {
              dispatch(
                userServiceApiSlice.util.invalidateTags([
                  { type: "User", id: "OrgRequests" },
                ])
              );
              setIsSuccessMessageVisible(true); // Show success message only if the sync was initiated by the user
              setIsSyncInitiatedByUser(false); // Reset the flag
            }
          }
        }, 1000);
      })
      .catch((error) => {
        console.error("Sync error:", error);
        console.error("Request payload:", { orgId }); // Log the orgId being sent
        setIsSyncInProgress(false); // Set sync not in progress in case of error
      });
  };

  // Determine Button State
  const isButtonDisabled = useMemo(() => {
    if (isPopulateLoading || isSyncInProgress) return true; // Button disabled during loading or sync in progress
    return populateStatus?.status === "in_progress"; // Button disabled if status is "in_progress"
  }, [isPopulateLoading, isSyncInProgress, populateStatus]);

  // Key for re-rendering the datagrid
  const dataGridKey = JSON.stringify(rows);

  // Custom locale text for datagrid empty rows
  const customLocaleText = {
    noRowsLabel: "No Users Found",
  };

  const [selectedRows, setSelectedRows] = useState([]);

  // Sets selected rows to the selected rows in the datagrid
  const handleSelectionModelChange = (selection) => {
    setSelectedRows(selection);
  };

  const [recordsPerPage] = useState(25);

  // Search TextField styles
  const textStyles = styled({
    root: {
      "& .Mui-focused": {
        color: "#008080",
        outline: "1px solid  #008080",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "14px",
      },
    },
  });

  const [currentRecords, setCurrentRecords] = useState(queryData);

  useEffect(() => {
    if (userPermissions && orgId) {
      const orgPermissions = userPermissions.find(
        (permission) => permission.orgId === orgId
      );
      setPermissions(orgPermissions);
    }
  }, [userPermissions, orgId]);

  // Invalidate the tags when the status becomes "ended"
  useEffect(() => {
    if (populateStatusData?.status === "ended") {
      setIsSyncInProgress(false); // Set sync not in progress
      if (isSyncInitiatedByUser) {
        setIsSuccessMessageVisible(true); // Show success message only if the sync was initiated by the user
      }
      // Invalidate the tags to trigger a refetch
      dispatch(
        userServiceApiSlice.util.invalidateTags([
          { type: "User", id: "OrgRequests" },
        ])
      );
    } else if (populateStatusData?.status === "in_progress") {
      setIsSyncInProgress(true); // Set sync in progress
    }
  }, [populateStatusData, dispatch]);

  useEffect(() => {
    if (queryData) {
      setCurrentRecords(queryData);
    }
  }, [currentPage, recordsPerPage, queryData]);

  const calcPages = useMemo(() => {
    return Math.ceil(totalRecords / recordsPerPage);
  }, [totalRecords]);

  const handleSearchChange = (event) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
    } else {
      setShowMultiSelectModal(false);
      setSelectedRows([]);
    }
  };

  const openModal = () => {
    setShowMultiSelectModal(true);
  };

  // Batch update user org status for multi-select functionality
  const [
    batchUpdateUserOrgStatus,
    { data: batchUpdateData, isLoading: isBatchUpdateUserOrgStatusLoading },
  ] = useUpdateUserOrgStatusBatchMutation();

  const action = (platformId, orgId, userEmail) => {
    return (rejectReason, otherReason, rowData) => {
      batchUpdateUserOrgStatus({
        platformId: platformId,
        orgId: orgId,
        userEmail: userEmail,
        rejectReason: rejectReason,
        otherReason: otherReason,
        rowData: rowData,
      });
    };
  };

  // Interval check for initial populate status
  useEffect(() => {
    if (populateStatusData?.status === "in_progress") {
      setIsSyncInProgress(true); // Set sync in progress

      // Start interval to check populate status every 30 seconds
      const intervalId = setInterval(() => {
        refetchPopulateStatus();
      }, 30000);

      // Clear interval when status becomes "ended"
      const checkStatus = () => {
        if (populateStatusData?.status === "ended") {
          clearInterval(intervalId);
          setIsSyncInProgress(false); // Set sync not in progress
          if (isSyncInitiatedByUser) {
            setIsSuccessMessageVisible(true); // Show success message only if the sync was initiated by the user
          }
          // Invalidate the tags to trigger a refetch
          dispatch(
            userServiceApiSlice.util.invalidateTags([
              { type: "User", id: "OrgRequests" },
            ])
          );
        }
      };

      // Use another interval to check the status every second
      const statusCheckInterval = setInterval(() => {
        checkStatus();
        if (populateStatusData?.status === "ended") {
          clearInterval(statusCheckInterval);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
        clearInterval(statusCheckInterval);
      };
    }
  }, [populateStatusData, refetchPopulateStatus]);

  useEffect(() => {
    if (isSuccessMessageVisible) {
      const timer = setTimeout(() => {
        setIsSuccessMessageVisible(false);
      }, 7000); // Auto close after 5 seconds

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isSuccessMessageVisible]);

  useEffect(() => {
    if (rows) {
      setQueryData(rows);
      setIsDataProcessingLoading(false);
    }
  }, [rows]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <GridFooterContainer sx={{ justifyContent: "flex-start" }}>
          {selectedRows.length > 0 && (
            <div style={{ justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#fe0000",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  padding: "2px 14px",
                  borderRadius: "10px",
                  fontSize: "12px",
                  color: "white",
                  marginRight: "5px",
                  "&:hover": {
                    backgroundColor: "#fe0000",
                    color: "white",
                  },
                }}
                onClick={() => openModal()}
              >
                {viewType === "Joined History" ? "Revoke All" : "Reset All"}
              </Button>
            </div>
          )}
          <GridFooter
            sx={{
              border: "none",
              display: "flex",
              justifyContent: "space-between",
            }}
          />
        </GridFooterContainer>
        <GridFooterContainer>
          <LargeDataPagination
            nPages={calcPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </GridFooterContainer>
      </GridFooterContainer>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "3rem",
      }}
    >
      {showMultiSelectModal && (
        <ReusableMultiSelectModal
          show={showMultiSelectModal}
          close={handleClose}
          selectedRows={selectedRows}
          allRows={queryData}
          action={() => action(platformData?._id, orgId, userEmail)}
        />
      )}
      <SyncUsersConfirmationModal
        show={isSyncModalOpen}
        close={closeSyncModal}
        handleConfirmChange={handleSyncClick}
        OrgName={orgName}
      />
      <div
        style={{
          width: "100%",
          padding: "1rem",
          paddingTop: "0",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: "18px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: "18px",
              display: "flex",
              flexDirection: "column",
              width: "60%",
              marginBottom: "0.5rem",
            }}
          >
            {orgName || ""}
            <Typography variant="subtitle1">Org History</Typography>
          </div>

          {/* Sync Button - Show only for Requested */}
          <ThemeProvider theme={welcomeToolTipTheme}>
            {isUserAuthorized &&
              tabDescription.includes("Users in Requested") && (
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      {
                        "This action syncs the user's membership status in our database with the latest available information from GitHub."
                      }
                      <br />
                      {
                        "If users are stuck in Requested state for more than 2 hours, please raise an ECR support request."
                      }
                    </div>
                  }
                  sx={{
                    marginLeft: "5px",
                    color: "#008080",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ display: "inline-block", minWidth: "8rem" }}>
                    <LoadingButton
                      text={"Sync Users"}
                      variant={"contained"}
                      backgroundColor={"#86BC25"}
                      color={"#fff"}
                      cursor={
                        populateStatus.status === "in_progress"
                          ? "disabled"
                          : "pointer"
                      }
                      onClickFunc={openSyncModal}
                      loadingState={
                        isSyncInProgress ||
                        isSyncUsersLoading ||
                        populateStatus.status === "in_progress"
                      }
                      loadingText={
                        isSyncInProgress ||
                          populateStatus.status === "in_progress"
                          ? "In Progress"
                          : "Syncing..."
                      }
                      disabled={
                        isButtonDisabled ||
                        isSyncUsersLoading ||
                        rows.length === 0
                      }
                    />
                  </div>
                </Tooltip>
              )}
          </ThemeProvider>
          {/* Sync success or progress message */}
          {(isSyncInProgress ||
            isSyncUsersLoading ||
            isSuccessMessageVisible ||
            populateStatusData?.status === "in_progress") && (
              <Box
                sx={{
                  // mt: 1.5,
                  p: 0.8,
                  backgroundColor:
                    isSyncInProgress ||
                      isSyncUsersLoading ||
                      populateStatusData?.status === "in_progress"
                      ? "#fff3cd"
                      : "#e8f5e9",
                  borderRadius: "8px",
                  border: "1px solid",
                  borderColor:
                    isSyncInProgress ||
                      isSyncUsersLoading ||
                      populateStatusData?.status === "in_progress"
                      ? "#ffeeba"
                      : "#b9f6ca",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Show clock icon when sync is in progress and Show success tick icon when sync is successful */}

                {
                  isSyncInProgress ||
                    isSyncUsersLoading ||
                    populateStatusData?.status === "in_progress" ? (
                    <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                      <AccessTimeIcon
                        sx={{
                          color: "#856404",
                        }}
                      />
                    </Zoom>
                  ) : isSuccessMessageVisible ? (
                    <Zoom in={true} style={{ transitionDelay: "100ms" }}>
                      <TaskAltIcon
                        sx={{
                          color: "#2e7d32",
                        }}
                      />
                    </Zoom>
                  ) : null
                }
                <Typography
                  variant="subtitle2"
                  sx={{
                    color:
                      isSyncInProgress ||
                        isSyncUsersLoading ||
                        populateStatusData?.status === "in_progress"
                        ? "#856404"
                        : "#2e7d32",
                    fontWeight: "medium",
                  }}
                >
                  {isSyncInProgress ||
                    isSyncUsersLoading ||
                    populateStatusData?.status === "in_progress"
                    ? "Sync In Progress, please wait.."
                    : "Success! Users Synced"}
                </Typography>
              </Box>
            )}
        </div>
        <hr style={{ backgroundColor: "#d0d7de", width: "100%" }}></hr>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#808080",
              lineHeight: "1.5",
              marginTop: "0.5rem",
              width: "60%",
            }}
          >
            {tabDescription}
          </span>
          {/* Search TextField */}
          <TextField
            id="outlined-search"
            placeholder="Search Users By Email"
            width="100%"
            type="search"
            className={textStyles.root}
            variant="outlined"
            InputProps={{
              style: {
                height: "31px",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: "600px", width: "100%" }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "13px !important",
              fontWeight: "normal !important",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#f6f6f6",
              maxHeight: "45px",
              fontSize: "12px !important",
            },
            "& .MuiDataGrid-row": {
              minHeight: "60px !important",
              boxShadow: "none !important",
              borderBottom: "1px solid #d4d4d4",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "hidden",
            },
          }}
          components={{ Footer: CustomFooter }}
          key={dataGridKey}
          getRowId={(row) => row.id}
          rows={currentRecords}
          columns={columns}
          disableVirtualization
          disableSelectionOnClick
          checkboxSelection={checkboxSelection}
          localeText={customLocaleText}
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectedRows}
          isRowSelectable={(params) =>
            params.row.status === "joined" ||
            params.row.status === "rejected" ||
            params.row.status === "removed"
          }
          loadingOverlayClassName="custom-loading-indicator"
          loading={
            isLoading ||
            isDataProcessingloading ||
            isBatchUpdateUserOrgStatusLoading
          }
        />
      </div>
    </div>
  );
};

export default ReusableDataGrid;
